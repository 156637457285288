const flatMap = (array, fn) => {
  var result = [];
  for (var i = 0; i < array.length; i++) {
    var mapping = fn(array[i]);
    result = result.concat(mapping);
  }
  return result;
};

export const productOptionsFormatter = (option) => {
  if (option === null) option = "";

  let result = "";
  result = flatMap(option.split("|"), function (part) {
    return [part, <br />];
  });
  // Remove the last spacer
  result.pop();
  return (
    <div>
      {result}
      <br />
    </div>
  );
};

export const addressFormatter = (address) => {
  return address?.length > 22 ? address?.substring(0, 19) + "..." : address;
};
