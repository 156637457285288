import axios from "axios";
import { logout } from "../store/actions";

import { HTTP_REQUEST } from "../store/actions/types";

const baseUrl = "";

export default ({ getState }) =>
  (next) =>
  async ({ payload = {}, type }) => {
    const {
      body = null,
      headers,
      label,
      method,
      transformData = (data) => data,
      callBack,
      errorHandler,
      url,
    } = payload || {};

    next({ payload, type });

    if (type !== HTTP_REQUEST) {
      return;
    }

    const { accessToken, isAuthenticated } = getState().authResponse || {};

    let AuthHeader = { Authorization: null };
    // TODO : Check auth header field and modify
    if (isAuthenticated) {
      AuthHeader.Authorization = `Bearer ${accessToken}`;
      //Accept-Language = tr
    }

    next({
      type: `${label}_REQUEST`,
    });

    try {
      const options = {
        url,
        baseURL: baseUrl,
        headers: { ...headers, ...AuthHeader },
        method,
        data: body,
      };

      const res = await axios(options);

      const { data } = res;

      if (res.status >= 400) {
        // eslint-disable-next-line no-throw-literal
        throw { ...data, response: { status: res.status } };
      }

      // eslint-disable-next-line consistent-return
      next({
        type: `${label}_SUCCESS`,
        payload: transformData(data),
      });

      if (typeof callBack === "function") {
        callBack(data);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // TODO: Set Authorization Process
        logout();
        if (!window.location.href.includes("#/giris"))
          window.location.replace("/");
        console.error("error", error?.message);
      }

      const enhancedError = {
        ...error?.response?.data,
        message: error?.response?.data?.message,
      };

      if (typeof errorHandler === "function") {
        errorHandler(enhancedError);
      }

      // eslint-disable-next-line consistent-return
      return next({
        type: `${label}_FAILURE`,
        payload: enhancedError,
      });
    }
  };
