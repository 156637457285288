import moment from "moment";

export const modifyDate = (date) => {
  return moment(date).format("DD.MM.YYYY HH:mm");
};

export const modifyDateDayMonthYear = (date) => {
  return moment(date).format("DD.MM.YYYY");
};
export const modifyDateYearMonthDay = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const modifyDateTime = (date) => {
  return moment(date).format("YYYY.MM.DD HH:mm:ss.SSS");
};
