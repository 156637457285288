import axios from "axios";
import { logout } from "../store/actions";
const { REACT_APP_API_URL } = process.env;

let errorMessage = "";
let bearer_token = null;

export const baseURL = "";
debugger;
let serviceCaller = axios.create({
  baseURL: baseURL,
  timeout: 60000,
});

export function setToken(token) {
  bearer_token = token;
}

// Add a request interceptor
serviceCaller.interceptors.request.use(
  (config) => {
    // Your Interceptor code to do something with the request data
    // Return config
    //config.headers["X-Requested-With"] = "XMLHttpRequest"
    if (bearer_token && bearer_token.length && !config.url.includes("/token")) {
      config.headers.Authorization = "Bearer " + bearer_token;
    }
    // console.log("error.config", config)
    return config;
  },
  (error) => {
    // Your Interceptor code to do something with request error
    // Return error
    let errorMsg = handleNetworkErrors(error);
    setTimeout(() => {
      console.log(errorMsg);
    }, 200);
    return Promise.reject(errorMsg);
  }
);

serviceCaller.interceptors.response.use(
  (response) => {
    // Your Interceptor code to do something with the response data
    if (isResponseOk(response)) {
      // Return response data
      return response.data;
    } else {
      // Return error
      setTimeout(() => {
        console.log(errorMessage);
      }, 200);
      return Promise.reject(errorMessage);
    }
  },
  (error) => {
    let errorMsg = handleNetworkErrors(error);
    if (error && error.config) {
      // console.log("errorAxios",error)
      if (error.response.status === 401) {
        if (bearer_token !== null) {
          setToken(null);
          logout();
        }
        if (!window.location.href.includes("#/giris"))
          window.location.replace("/");
      } else if (error.config.url !== "/auth") {
        setTimeout(() => {
          console.log(errorMessage);
        }, 200);
      }
    }
    return Promise.reject(errorMsg);
  }
);

const isResponseOk = (response) => {
  if (response && response.data) {
    errorMessage = "";
    return true;
  } else {
    errorMessage = "Error occured";
    return false;
  }
};

const handleNetworkErrors = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return error.response.data.error;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return error.request._response;
  } else {
    // Something happened in setting up the request that triggered an Error
    return error.message;
  }
};

export default serviceCaller;
