import React from "react";
import { CSelect } from "@coreui/react";
import PropTypes from "prop-types";

export const EntegreyemekSelect = ({
  selectData,
  defaultValue,
  setOnChange,
}) => {
  return (
    <CSelect
      style={{ padding: 5 }}
      value={defaultValue}
      onChange={setOnChange}
      name="select"
      id="select"
    >
      {selectData?.map((item, index) => {
        return (
          <option key={index} value={item.cancellation_Id}>
            {item.cancellation_reason}
          </option>
        );
      })}
    </CSelect>
  );
};

EntegreyemekSelect.propTypes = {
  selectData: PropTypes.array,
  defaultValue: PropTypes.string,
  setOnChange: PropTypes.func,
};
EntegreyemekSelect.defaultProps = {
  selectData: [],
};
