import { updateObject } from "../Utility";
import * as actionTypes from "../actions/types";

const initialState = {
  commonModal: false,
  modalData: [],
  restaurantProviderModal: false,
  orderDetailModal: false,
};

const modalOnchangeReducer = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ON_CHANGE_MODAL:
      return modalOnchangeReducer(state, action);
    default:
      return state;
  }
};

export default reducer;
