import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CDataTable,
  CSpinner,
  CCol,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
} from "@coreui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { orderDetailTableFields } from "./tableFields";
import { modifyDate } from "../../../utils/DateFormatter";
import { Colorset } from "../theme";
import { ModalContainer, EntegreyemekSelect } from "../../component";

import {
  PENDING,
  ACCEPTED,
  CANCELLED,
  ONDELIVERY,
  DELIVERED,
} from "../../../utils/OrderUpdateStatus";
import { useHistory } from "react-router-dom";
import {
  activationOnChange,
  modalOnchangeActionFunction,
  GetCancellationReasons,
  UpdateOrder,
} from "../../../store/actions";
import { productOptionsFormatter } from "../../../utils/DataListHelper";

const OrderDetailModal = ({ visible, setVisible, item }) => {
  const dispatch = useDispatch();
  let { loading } = useSelector((state) => state.ActivationResponse);
  let { orderDetailModal } = useSelector((state) => state.ModalControlResponse);

  const [toastState, setToastState] = useState(false);
  const [cancellationReasons, setCancellationReasons] = useState([]);
  const [reasonRejection, setReasonRejection] = useState(false);

  const [reasonId, setReasonId] = useState(null);

  const history = useHistory();

  const sendDispatchAndRedirectPrintPage = async () => {
    dispatch(activationOnChange({ props: "printItem", value: item }));
    await localStorage.setItem("printItem", JSON.stringify(item));
    await localStorage.setItem("restaurant", JSON.stringify(item.restaurant));
    window.open("/#/print", "page");

    // history.push("/print")
  };

  const updateOrder = (updateType, reason) => {
    let body = {
      order_id: item?.id,
      status: updateType,
    };

    if (reason !== null) {
      body.reason = cancellationReasons.filter(
        (item) => item.cancellation_Id === reason
      )[0]?.cancellation_reason;

      body.reasonId = reason;
    }

    dispatch(activationOnChange({ props: "updatedOrderItem", value: item }));
    dispatch(activationOnChange({ props: "updateStatu", value: updateType }));
    dispatch(
      UpdateOrder(
        body,
        (res) => {
          toast.success(res?.message, { autoClose: 2000 });
          setToastState(true);
        },
        (err) => {
          setToastState(true);
          toast.error(err.error, { autoClose: 3000 });
        }
      )
    );
  };

  const getActionStatus = (item) => {
    switch (item?.delivery_status) {
      case PENDING:
        return (
          <CButton
            style={{ borderRadius: 7 }}
            type="button"
            className="btn btn-square btn-success"
            onClick={() => updateOrder(ACCEPTED, null)}
          >
            Onayla
          </CButton>
        );
      case ACCEPTED:
        return (
          <CRow
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <CCol>
              <button
                style={{ borderRadius: 7 }}
                type="button"
                id="cancel_order_btn_in_modal"
                className="btn btn-square btn-danger"
                onClick={() => {
                  setReasonRejection(true);
                  dispatch(
                    modalOnchangeActionFunction({
                      props: "orderDetailModal",
                      value: false,
                    })
                  );
                  dispatch(
                    GetCancellationReasons(
                      { orderId: item?.id },
                      (res) => {
                        if (Array.isArray(res)) {
                          setCancellationReasons(res);
                        } else {
                          toast.error("Reddetme nedenleri yüklenemedi", {
                            autoClose: 3000,
                          });
                          setCancellationReasons([]);
                        }
                        dispatch(
                          activationOnChange({ props: "loading", value: false })
                        );
                      },
                      (err) => {
                        dispatch(
                          activationOnChange({ props: "loading", value: false })
                        );
                        toast.error("Sipariş reddetme başarısız.", {
                          autoClose: 3000,
                        });
                      }
                    )
                  );
                }}
              >
                Reddet
              </button>
            </CCol>
            <CRow style={{ marginRight: 10 }}>
              <button
                className="btn btn-square btn-info"
                style={{
                  backgroundColor: "#20a8d8",
                  marginRight: 5,
                  borderRadius: 7,
                }}
                onClick={() => sendDispatchAndRedirectPrintPage()}
              >
                Siparişi Yazdır
              </button>
              <CButton
                style={{ borderRadius: 7 }}
                className="btn btn-square btn-success"
                onClick={() => updateOrder(ONDELIVERY, null)}
              >
                Yola Çıkar
              </CButton>
            </CRow>
          </CRow>
        );
      case ONDELIVERY:
        return (
          <CRow
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <CCol>
              <button
                style={{ borderRadius: 7 }}
                type="button"
                id="cancel_order_btn_in_modal"
                className="btn btn-square btn-danger"
                onClick={() => {
                  setReasonRejection(true);
                  dispatch(
                    modalOnchangeActionFunction({
                      props: "orderDetailModal",
                      value: false,
                    })
                  );
                  dispatch(
                    GetCancellationReasons(
                      { orderId: item?.id },
                      (res) => {
                        if (Array.isArray(res)) {
                          setCancellationReasons(res);
                        } else {
                          toast.error("Reddetme nedenleri yüklenemedi", {
                            autoClose: 3000,
                          });
                          setCancellationReasons([]);
                        }
                        dispatch(
                          activationOnChange({ props: "loading", value: false })
                        );
                      },
                      (err) => {
                        dispatch(
                          activationOnChange({ props: "loading", value: false })
                        );
                        toast.error("Sipariş reddetme başarısız.", {
                          autoClose: 3000,
                        });
                      }
                    )
                  );
                }}
              >
                Reddet
              </button>
            </CCol>
            <CRow style={{ marginRight: 10 }}>
              <CButton
                className="btn btn-square"
                color="primary"
                style={{ marginRight: 5, borderRadius: 7 }}
                onClick={() => sendDispatchAndRedirectPrintPage()}
              >
                Siparişi Yazdır
              </CButton>
              <CButton
                style={{ borderRadius: 7 }}
                className="btn btn-square btn-success"
                onClick={() => {
                  updateOrder(DELIVERED, null);
                }}
              >
                Teslim Edildi
              </CButton>
            </CRow>
          </CRow>
        );
      case DELIVERED:
        return (
          <CButton
            color="primary"
            onClick={() => sendDispatchAndRedirectPrintPage()}
          >
            Siparişi Yazdır
          </CButton>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <ModalContainer
        onClose={() => setReasonRejection(false)}
        show={reasonRejection}
        container={
          <CRow>
            {toastState ? <ToastContainer /> : null}
            <EntegreyemekSelect
              setOnChange={(e) => setReasonId(e.target.value)}
              selectData={cancellationReasons}
            />
            <CModalFooter
              style={{
                justifyContent: "flex-end",
                flex: 1,
                flexDirection: "row",
              }}
            >
              {loading ? (
                <CSpinner style={{ color: Colorset.eyColor }} size={"sm"} />
              ) : (
                <button
                  style={{ borderRadius: 7 }}
                  type="button"
                  id="cancel_order_btn_in_modal"
                  className="btn btn-square btn-danger"
                  color="danger"
                  onClick={() => {
                    setReasonRejection(false);
                    let reason = reasonId;
                    if (
                      reason === null &&
                      cancellationReasons !== null &&
                      cancellationReasons.length > 0
                    ) {
                      reason = cancellationReasons[0].cancellation_Id;
                    }
                    updateOrder(CANCELLED, reason);
                  }}
                >
                  Reddet
                </button>
              )}
            </CModalFooter>
          </CRow>
        }
        title={"Reddetme Nedeni"}
      />
      <CModal
        show={orderDetailModal}
        size={"xl"}
        closeOnBackdrop={false}
        onClose={() =>
          dispatch(
            modalOnchangeActionFunction({
              props: "orderDetailModal",
              value: false,
            })
          )
        }
        scrollable
        centered
      >
        <CModalHeader style={{ justifyContent: "space-between" }}>
          <CModalTitle>Sipariş Detayları (#{item?.order_out_id})</CModalTitle>
          <CButton
            onClick={() =>
              dispatch(
                modalOnchangeActionFunction({
                  props: "orderDetailModal",
                  value: false,
                })
              )
            }
          >
            X
          </CButton>
        </CModalHeader>
        <CModalBody style={{ backgroundColor: "#ebedef" }}>
          <CCol>
            <CRow
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 2,
                backgroundColor: Colorset.whiteColor,
              }}
            >
              <CCol className="col-lg-4">
                <strong>Teslim Tarihi:</strong>
              </CCol>
              <CCol className="col-lg-8">{modifyDate(item?.order_date)}</CCol>
            </CRow>
            <CRow
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 2,
                backgroundColor: Colorset.warningYellow,
              }}
            >
              <CCol className="col-lg-4">
                <strong>Teslimat Tipi:</strong>
              </CCol>
              <CCol className="col-lg-8">
                {item?.is_service_provider_courier === true
                  ? "Kurye teslim edecek"
                  : item?.order_delivery_type === "Pickup Order"
                  ? "Gel Al siparişi"
                  : "Restoran teslim edecek"}
              </CCol>
            </CRow>
            <CRow
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 2,
                backgroundColor: Colorset.whiteColor,
              }}
            >
              <CCol className="col-lg-4">
                <strong>Ödeme Şekli:</strong>
              </CCol>
              <CCol className="col-lg-8">{item?.payment_method}</CCol>
            </CRow>
            <CRow
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 2,
                backgroundColor: Colorset.dangerRed,
                color: Colorset.whiteColor,
              }}
            >
              <CCol className="col-lg-4">
                <strong>Müşteri Notu:</strong>
              </CCol>
              <CCol className="col-lg-8">{item?.order_note}</CCol>
            </CRow>

            <CRow
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 2,
                backgroundColor: Colorset.whiteColor,
              }}
            >
              <CCol className="col-lg-4">
                <strong>Müşteri:</strong>
              </CCol>
              <CCol className="col-lg-8">{item?.customer_name}</CCol>
            </CRow>
            <CRow
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 2,
                backgroundColor: Colorset.whiteColor,
              }}
            >
              <CCol className="col-lg-4">
                <strong>Telefon:</strong>
              </CCol>
              <CCol className="col-lg-8">
                {item?.customer_phone_number}{" "}
                {item?.customer_phone_number2 !== null &&
                item?.customer_phone_number2 !== ""
                  ? "veya " + item?.customer_phone_number2
                  : ""}
              </CCol>
            </CRow>
            <CRow
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                marginBottom: 2,
                backgroundColor: Colorset.whiteColor,
              }}
            >
              <CCol className="col-lg-4">
                <strong>Adres:</strong>
              </CCol>
              <CCol className="col-lg-8">
                {item?.customer_address} <br />{" "}
                {item?.customer_address_description}
              </CCol>
            </CRow>
          </CCol>

          <CCard style={{ marginTop: 20 }}>
            <CCardHeader>Sipariş İçeriği</CCardHeader>
            <CCardBody>
              <CDataTable
                items={item?.products}
                fields={orderDetailTableFields}
                noItemsView={{
                  noResults: "Filtreye uygun bir sonuç bulunamadı.",
                  noItems: "Sipariş bulunmamaktadır.",
                }}
                striped
                scopedSlots={{
                  quantity: (item, index) => <td>{item?.quantity}</td>,
                  productOptions: (item, index) => (
                    <td>
                      {item?.productOptions == null
                        ? ""
                        : item?.productOptions.map((option) =>
                            productOptionsFormatter(option.name)
                          )}
                    </td>
                  ),
                  productNote: (item, index) => <td>{item?.product_note}</td>,
                  price: (item, index) => <td>{item?.price} ₺</td>,
                  total: (item, index) => (
                    <td>{item?.quantity * item?.price} ₺</td>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
        </CModalBody>
        <CModalFooter>
          {loading ? (
            <CSpinner style={{ color: Colorset.eyColor }} size={"sm"} />
          ) : (
            getActionStatus(item)
          )}
        </CModalFooter>
      </CModal>
    </>
  );
};

export default OrderDetailModal;
