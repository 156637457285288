export const HTTP_REQUEST = "HTTP_REQUEST";

export const LOGIN = "LOGIN";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const ON_CHANGE_TEXT = "on_change_text";
export const AUTH_SUCCESS = "login_successful";
export const AUTH_FAIL = "login_fail";
export const AUTH_START = "login_start";
export const AUTH_LOGOUT = "login_logout";
export const SET_AUTH_REDIRECT_PATH = "login_aut";

export const ON_CHANGE_SIDEBAR = "on_change_sidebar";

export const ON_CHANGE_MODAL = "on_change_modal";

export const ON_CHANGE_ACTIVATION = "on_change_modal";
export const ON_CHANGE_MENU = "ON_CHANGE_MENU";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const CHANGE_RESTAURANTSETTINGS = "CHANGE_RESTAURANTSETTINGS";
export const CHANGE_RESTAURANTSETTINGS_REQUEST =
  "CHANGE_RESTAURANTSETTINGS_REQUEST";
export const CHANGE_RESTAURANTSETTINGS_SUCCESS =
  "CHANGE_RESTAURANTSETTINGS_SUCCESS";
export const CHANGE_RESTAURANTSETTINGS_FAILURE =
  "CHANGE_RESTAURANTSETTINGS_FAILURE";

export const GET_RESTAURANT = "GET_RESTAURANT";
export const GET_RESTAURANT_REQUEST = "GET_RESTAURANT_REQUEST";
export const GET_RESTAURANT_SUCCESS = "GET_RESTAURANT_SUCCESS";
export const GET_RESTAURANT_FAILURE = "GET_RESTAURANT_FAILURE";

export const GET_PROVIDERS = "GET_PROVIDERS";
export const GET_PROVIDERS_REQUEST = "GET_PROVIDERS_REQUEST";
export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS";
export const GET_PROVIDERS_FAILURE = "GET_PROVIDERS_FAILURE";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const UPDATE_ALL_PROVIDER = "UPDATE_ALL_PROVIDER";
export const UPDATE_ALL_PROVIDER_REQUEST = "UPDATE_ALL_PROVIDER_REQUEST";
export const UPDATE_ALL_PROVIDER_SUCCESS = "UPDATE_ALL_PROVIDER_SUCCESS";
export const UPDATE_ALL_PROVIDER_FAILURE = "UPDATE_ALL_PROVIDER_FAILURE";

export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const GET_ORDER_HISTORY_REQUEST = "GET_ORDER_HISTORY_REQUEST";

export const GET_REPORT_DASHBOARD = "GET_REPORT_DASHBOARD";
export const GET_REPORT_DASHBOARD_REQUEST = "GET_REPORT_DASHBOARD_REQUEST";

export const GET_CANCELLATIONREASONS = "GET_CANCELLATIONREASONS";

export const GET_MENUS = "GET_MENUS";
export const GET_MENUS_REQUEST = "GET_MENUS_REQUEST";
export const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS";
export const GET_MENUS_FAILURE = "GET_MENUS_FAILURE";

export const UPDATE_PRODUCT_STATUS = "UPDATE_PRODUCT_STATUS";
export const UPDATE_PRODUCT_STATUS_REQUEST = "UPDATE_PRODUCT_STATUS_REQUEST";
export const UPDATE_PRODUCT_STATUS_SUCCESS = "UPDATE_PRODUCT_STATUS_SUCCESS";
export const UPDATE_PRODUCT_STATUS_FAILURE = "UPDATE_PRODUCT_STATUS_FAILURE";

export const ADD_SERVICE_PROVIDER = "ADD_SERVICE_PROVIDER";
export const ADD_SERVICE_PROVIDER_REQUEST = "ADD_SERVICE_PROVIDER_REQUEST";
export const ADD_SERVICE_PROVIDER_SUCCESS = "ADD_SERVICE_PROVIDER_SUCCESS";
export const ADD_SERVICE_PROVIDER_FAILURE = "ADD_SERVICE_PROVIDER_FAILURE";
