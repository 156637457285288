import React from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { authCheckState } from "./store/actions";
import "./scss/style.scss";
import { CSpinner } from "@coreui/react";
import { Colorset } from "./views/component";
import * as actions from "./store/actions";
import { connect } from "react-redux";
import { setToken } from "./config/axios";

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(authCheckState());
  }, []);

  let routes = (
    <Switch>
      <Route
        exact
        path="/giris"
        name="Login Page"
        render={(props) => <Login {...props} />}
      />
      <Route
        exact
        path="/404"
        name="Page 404"
        render={(props) => <Page404 {...props} />}
      />
      <Route
        exact
        path="/500"
        name="Page 500"
        render={(props) => <Page500 {...props} />}
      />
      <PrivateRoute
        path="/"
        isAuthenticated={
          typeof localStorage.getItem("accessToken") !== undefined &&
          localStorage.getItem("accessToken") !== null
            ? true
            : false
        }
      >
        <TheLayout />
      </PrivateRoute>
    </Switch>
  );

  return (
    <HashRouter>
      <React.Suspense
        fallback={
          <div className={"sk-spinner sk-spinner-pulse text-center"}>
            <CSpinner style={{ color: Colorset.eyColor }} size={"lg"} />
          </div>
        }
      >
        {routes}
      </React.Suspense>
    </HashRouter>
  );
};

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/giris",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
const mapStateToProps = ({ authResponse }) => {
  const { accessToken } = authResponse;
  setToken(accessToken);
  return { isAuthenticated: accessToken !== null };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
