import * as actionTypes from "../actions/types";
import { updateObject } from "../Utility";

const initialState = {
  sidebarShow: true,
  sound:
    localStorage.getItem("sound") === null ||
    localStorage.getItem("sound") === undefined
      ? "on"
      : localStorage.getItem("sound"),
  soundControl: true,
};

const onChangeSidebar = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ON_CHANGE_SIDEBAR:
      return onChangeSidebar(state, action);
    default:
      return state;
  }
};

export default reducer;
