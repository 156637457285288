import * as actionTypes from "../actions/types";
import { updateObject } from "../Utility";

const initialState = {
  providersInformation: null,
  error: null,
  getProviderLoading: false,
  restaurantInformation: null,
  allUpdateProvider: false,
  allUpdateProviderLoading: false,
  general_status: "",
};

const onChangeSidebar = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};
const getProviderSuccess = (state, action) => {
  let result2 = action.payload.filter((item) => item?.status === "Closed");
  let result3 = action.payload.filter((item) => item?.status === "HugeDemand");

  return updateObject(state, {
    error: null,
    getProviderLoading: false,
    providersInformation: action.payload,
    general_status:
      result2?.length > 0
        ? "Closed"
        : result3?.length > 0
        ? "HugeDemand"
        : "Open",
  });
};

const getProviderFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    getProviderLoading: false,
  });
};

const getProviderStart = (state, action) => {
  return updateObject(state, {
    error: null,
    getProviderLoading: true,
  });
};

const getRestaurantSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    restaurantInformation: action.payload,
  });
};

const getRestaurantFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    getProviderLoading: false,
  });
};

const getRestaurantStart = (state, action) => {
  return updateObject(state, {
    error: null,
    getProviderLoading: true,
  });
};

const updateAllProviderSuccess = (state, action) => {
  let result2 = action.payload.filter((item) => item?.status === "Open");
  let result3 = action.payload.filter((item) => item?.status === "HugeDemand");

  return updateObject(state, {
    error: null,
    allUpdateProviderLoading: false,
    providersInformation: action.payload,
    general_status:
      result2?.length > 0
        ? "Open"
        : result3?.length > 0
        ? "HugeDemand"
        : "Closed",
  });
};

const updateAllProviderFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    allUpdateProviderLoading: false,
  });
};

const updateAllProviderStart = (state, action) => {
  return updateObject(state, {
    error: null,
    allUpdateProviderLoading: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ON_CHANGE_TEXT:
      return onChangeSidebar(state, action);
    case actionTypes.GET_PROVIDERS_REQUEST:
      return getProviderStart(state, action);
    case actionTypes.GET_PROVIDERS_FAILURE:
      return getProviderFail(state, action);
    case actionTypes.GET_PROVIDERS_SUCCESS:
      return getProviderSuccess(state, action);

    case actionTypes.GET_RESTAURANT_REQUEST:
      return getRestaurantStart(state, action);
    case actionTypes.GET_RESTAURANT_FAILURE:
      return getRestaurantFail(state, action);
    case actionTypes.GET_RESTAURANT_SUCCESS:
      return getRestaurantSuccess(state, action);

    case actionTypes.UPDATE_ALL_PROVIDER_REQUEST:
      return updateAllProviderStart(state, action);
    case actionTypes.UPDATE_ALL_PROVIDER_FAILURE:
      return updateAllProviderFail(state, action);
    case actionTypes.UPDATE_ALL_PROVIDER_SUCCESS:
      return updateAllProviderSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
