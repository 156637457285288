import {
  ON_CHANGE_TEXT,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  SET_AUTH_REDIRECT_PATH,
} from "./types";
import { activationOnChange } from "./index";
import moment from "moment";
import serviceCaller, { setToken } from "../../config/axios";

export const authStart = () => {
  return {
    type: AUTH_START,
  };
};

export const authSuccess = (accessToken, userInfo) => {
  return {
    type: AUTH_SUCCESS,
    payload: { idToken: accessToken, userInfo: userInfo },
  };
};

export const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  setToken(null);
  serviceCaller.post("/api/user/logout", localStorage.getItem("userId"));

  //localStorage.clear();
  localStorage.removeItem("userId");
  localStorage.removeItem("userRole");
  localStorage.removeItem("userName");
  localStorage.removeItem("userSurname");
  localStorage.removeItem("userCountryCode");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("userPhoneNumber");
  localStorage.removeItem("userPictureUrl");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("token_type");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("restaurant_status");
  localStorage.removeItem("restaurant_service_time");

  return {
    type: AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      username: email,
      password: password,
    };

    serviceCaller
      .post("/api/user/oauth/token", authData)
      .then(async (response) => {
        let seconds = response.expires_in;
        const expirationDate = new Date(
          new Date().getTime() + moment(response.expires_in) * 1000
        );

        localStorage.setItem("userId", response.id);
        localStorage.setItem("userRole", response.role);
        localStorage.setItem("userName", response.name);
        localStorage.setItem("userSurname", response.surname);
        localStorage.setItem("userCountryCode", response.country_code);
        localStorage.setItem("userEmail", response.email);
        localStorage.setItem("userPhoneNumber", response.phone_number);
        localStorage.setItem("userPictureUrl", response.picture_url);
        localStorage.setItem("refreshToken", response.refresh_token);
        localStorage.setItem("accessToken", response.access_token);
        localStorage.setItem("token_type", response.token_type);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("restaurant_status", response.restaurant_status);
        localStorage.setItem(
          "restaurant_service_time",
          response.avg_delivery_time
        );

        const userInfo = {
          userId: response.id,
          userRole: response.role,
          userName: response.name,
          userSurname: response.surname,
          userCountryCode: response.country_code,
          userEmail: response.email,
          userPhoneNumber: response.phone_number,
          userPictureUrl: response.picture_url,
        };

        dispatch(authSuccess(response.access_token, userInfo));

        dispatch(checkAuthTimeout(seconds));

        dispatch(
          activationOnChange({
            props: "restaurant_status",
            value: response.restaurant_status,
          })
        );
        dispatch(
          activationOnChange({
            props: "restaurant_service_time",
            value: response.avg_delivery_time,
          })
        );
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userInfo = {
          userId: localStorage.getItem("userId"),
          userRole: localStorage.getItem("userRole"),
          userName: localStorage.getItem("userName"),
          userSurname: localStorage.getItem("userSurname"),
          userCountryCode: localStorage.getItem("userCountryCode"),
          userEmail: localStorage.getItem("userEmail"),
          userPhoneNumber: localStorage.getItem("userPhoneNumber"),
          userPictureUrl: localStorage.getItem("userPictureUrl"),
        };

        dispatch(authSuccess(accessToken, userInfo));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
