import {
  ON_CHANGE_TEXT,
  HTTP_REQUEST,
  GET_RESTAURANT,
  GET_PROVIDERS,
  UPDATE_ALL_PROVIDER,
} from "../actions/types";

import { baseURL } from "../../config/axios";

export const GetsettingsOnChange = ({ props, value }) => {
  return {
    type: ON_CHANGE_TEXT,
    payload: { props, value },
  };
};

export const GetProvider =
  (body, successCallback, errorHandler) => async (dispatch, getState) => {
    const url = baseURL + `/api/restaurant/${body?.restaurantId}/providers`;

    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "GET",
        url,
        body: { ...body },
        label: GET_PROVIDERS,
        callBack: (res) => successCallback(res),
        errorHandler: (event) => errorHandler(event),
      },
    });
  };

export const GetRestaurantInformation =
  (successCallback, errorHandler) => async (dispatch, getState) => {
    const url = baseURL + "/api/restaurant/details";
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "GET",
        url,
        label: GET_RESTAURANT,
        callBack: (res) => successCallback(res),
        errorHandler: (err) => errorHandler(err),
      },
    });
  };

export const UpdateAllProvider =
  (body, successCallback, errorHandler) => async (dispatch, getState) => {
    const url = baseURL + "/api/restaurant/provider/all/update";
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "POST",
        url,
        body: { ...body },
        label: UPDATE_ALL_PROVIDER,
        callBack: (res) => successCallback(res),
        errorHandler: (event) => errorHandler(event),
      },
    });
  };
