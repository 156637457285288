import React, { useEffect, useState } from "react";
import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CSwitch,
  CCol,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CImg,
  CDataTable,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  providerStatusArray,
  yemeksepetiProviderStatusArray,
} from "../../constants/FormData";
import {
  activationOnChange,
  ChangeCourierUpdate,
  ChangeRestaurantStatus,
  GetProvider,
  GetRestaurantInformation,
  GetsettingsOnChange,
  modalOnchangeActionFunction,
} from "../../store/actions";
import { ProviderInformationFields } from "./Fields/Fields";
import { EntegreyemekSelect } from "./EntegreyemekSelect";

export const ModalContainer = ({
  container,
  title,
  show,
  onClose,
  onClick,
  loading,
  size,
}) => {
  return (
    <CModal size={size} show={show} onClose={onClose}>
      <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer>{container}</CContainer>
      </CModalBody>
    </CModal>
  );
};

export const MarketSettingsModal = () => {
  const dispatch = useDispatch();
  const [restInfo, setRestInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    restaurantInformation,
    providersInformation,
    allUpdateProviderLoading,
  } = useSelector((state) => state.GetSettingsResponse);
  let { restaurantProviderModal } = useSelector(
    (state) => state.ModalControlResponse
  );
  let { providerStatuChangeloading } = useSelector(
    (state) => state.ActivationResponse
  );

  const UseProvider = (restaurantId) => {
    setLoading(true);
    dispatch(
      GetProvider(
        { restaurantId: restaurantId },
        () => {
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          toast.warn("Lütfen sayfayı yenileyiniz.", {
            autoClose: 3000,
          });
        }
      )
    );
  };

  useEffect(() => {
    if (restaurantProviderModal) {
      if (restaurantInformation === null) {
        dispatch(
          GetRestaurantInformation(
            (res) => {
              let list = [];
              for (const resKey in res) {
                list.push({
                  cancellation_Id: res[resKey].id,
                  cancellation_reason: res[resKey].name,
                });
              }
              setRestInfo(list);
              UseProvider(list[0].cancellation_Id);
            },
            (err) => {
              toast.error(err?.message, { autoClose: 3000 });
            }
          )
        );
      } else {
        let list = [];
        for (const resKey in restaurantInformation) {
          list.push({
            cancellation_Id: restaurantInformation[resKey].id,
            cancellation_reason: restaurantInformation[resKey].name,
          });
        }
        setRestInfo(list);
        UseProvider(list[0].cancellation_Id);
      }
    }
  }, [restaurantProviderModal]);

  return (
    <>
      <ToastContainer />
      <ModalContainer
        onClose={() => {
          dispatch(
            modalOnchangeActionFunction({
              props: "restaurantProviderModal",
              value: false,
            })
          );
        }}
        title={"Sipariş Sağlayıcıları"}
        show={restaurantProviderModal}
        size="lg"
        container={
          <CCard>
            {restaurantInformation === null ||
            restaurantInformation?.length === 1 ? null : (
              <CCardHeader>
                <CRow
                  style={{ justifyContent: "flex-end", alignItems: "center" }}
                >
                  <CCol style={{ maxWidth: 200, minWidth: 200 }}>
                    <strong>Şube Seçimi</strong>
                    <EntegreyemekSelect
                      selectData={restInfo}
                      setOnChange={(e) => {
                        UseProvider(e.target.value);
                      }}
                    />
                  </CCol>
                </CRow>
              </CCardHeader>
            )}

            <CCardBody>
              <CCol>
                <CDataTable
                  loading={
                    providerStatuChangeloading ||
                    allUpdateProviderLoading ||
                    loading
                  }
                  noItemsView={{
                    noResults: "Filtreye uygun bir sonuç bulunamadı.",
                    noItems: "Sağlayıcı bulunamadı.",
                  }}
                  items={providersInformation}
                  fields={
                    restaurantInformation === null ||
                    restaurantInformation?.length === 1
                      ? ProviderInformationFields.filter(
                          (i) => i.key !== "store_name"
                        )
                      : ProviderInformationFields
                  }
                  striped
                  scopedSlots={{
                    providerName: (item, index) => (
                      <td>
                        <CImg
                          style={{ borderRadius: 10 }}
                          src={item?.provider_logo_url}
                          alt={item?.provider_name}
                          width="32px"
                          height="32px"
                        />
                        &nbsp;
                        {item?.provider_name}
                      </td>
                    ),
                    store_name: (item, index) => (
                      <td>{item?.restaurant.name}</td>
                    ),
                    providerStatus: (item, index) => (
                      <td>
                        <EntegreyemekSelect
                          defaultValue={item?.status}
                          setOnChange={(e) => {
                            let status = e.target.value;
                            dispatch(
                              ChangeRestaurantStatus(
                                {
                                  restaurant_id: item?.restaurant_id,
                                  rest_service_provider_id:
                                    item?.rest_service_provider_id,
                                  status: status,
                                },
                                (res) => {
                                  dispatch(
                                    activationOnChange({
                                      props: "restaurant_status",
                                      value: status,
                                    })
                                  );

                                  providersInformation[index].status = status;
                                  dispatch(
                                    GetsettingsOnChange({
                                      props: "providersInformation",
                                      value: providersInformation,
                                    })
                                  );
                                  //UseProvider(item?.restaurant_id);
                                },
                                (err) => {
                                  toast.error("Hata meydana geldi. ", {
                                    autoClose: 3000,
                                  });
                                }
                              )
                            );
                          }}
                          selectData={
                            item?.provider_name === "Yemeksepeti"
                              ? yemeksepetiProviderStatusArray
                              : providerStatusArray
                          }
                        />
                      </td>
                    ),
                    providerCourierStatus: (item, index) =>
                      item?.provider_name === "Getir" ? (
                        <td>
                          <CSwitch
                            onChange={(e) => {
                              let status = e.target.checked;
                              dispatch(
                                ChangeCourierUpdate(
                                  {
                                    restaurant_id: item?.restaurant_id,
                                    rest_service_provider_id:
                                      item?.rest_service_provider_id,
                                    status: status ? "Enabled" : "Disabled",
                                    isAll: false,
                                  },
                                  (res) => {
                                    providersInformation[
                                      index
                                    ].courrier_status = status;
                                    dispatch(
                                      GetsettingsOnChange({
                                        props: "providersInformation",
                                        value: providersInformation,
                                      })
                                    );

                                    //UseProvider(item?.restaurant_id);
                                  },
                                  (err) => {
                                    setLoading(false);
                                    toast.error(
                                      item?.provider_name +
                                        " kurye durumu değişikliği başarısız.",
                                      {
                                        autoClose: 3000,
                                      }
                                    );
                                  }
                                )
                              );
                            }}
                            checked={item?.courrier_status}
                            className={"mx-1"}
                            color={"success"}
                            labelOn={"\u2713"}
                            labelOff={"\u2715"}
                            required
                            shape={"pill"}
                          />
                        </td>
                      ) : (
                        <td></td>
                      ),
                  }}
                />
              </CCol>
            </CCardBody>
          </CCard>
        }
      />
    </>
  );
};
