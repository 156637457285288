import { PENDING } from "../../utils/OrderUpdateStatus";
import * as actionTypes from "../actions/types";
import { updateObject } from "../Utility";
import {
  ADD_SERVICE_PROVIDER_REQUEST,
  ADD_SERVICE_PROVIDER_SUCCESS,
  UADD_SERVICE_PROVIDER_FAILURE,
} from "../actions/types";

const audio = new Audio("musics/dinner_bell.mp3");
const initialState = {
  loading: false,
  orderList: [],
  updatedOrderItem: null,
  modalItem: null,
  providerStatuChangeloading: false,
  providerProductUpdateLoading: false,
  addServiceProviderLoading: false,
  restaurant_status: localStorage.getItem("restaurant_status"),
  restaurant_service_time: localStorage.getItem("restaurant_service_time"),
  updateStatu: null,
  isOrderClicked: false,
  printItem: null,
  orderHistoryList: [],
  orderHistoryListLoader: false,
  menuList: [],
  selectedRestaurant: 0,
};

const onChangeSidebar = (state, action) => {
  return updateObject(state, { [action.payload.props]: action.payload.value });
};
const onChangeMenu = (state, action) => {
  if (Array.isArray(action.payload.value))
    return updateObject(state, {
      menuList: action.payload.value,
    });

  let newList = [];
  newList = state.menuList;

  for (let i = 0; i < newList.length; i++) {
    if (newList[i].id === action.payload.value.providerId) {
      for (let j = 0; j < newList[i].categories.length; j++) {
        if (newList[i].categories[j].id === action.payload.value.categoryId) {
          for (let k = 0; k < newList[i].categories[j].products.length; k++) {
            if (
              newList[i].categories[j].products[k].id ===
              action.payload.value.productId
            ) {
              newList[i].categories[j].products[k].status =
                action.payload.value.status;

              return updateObject(state, {
                menuList: newList,
              });
            }
          }
        }
      }
    }
  }

  return updateObject(state, {
    menuList: newList,
  });
};
const getOrderStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getOrderSuccess = (state, action) => {
  return updateObject(state, {
    orderList: action.payload,
    error: null,
    loading: false,
  });
};

const getOrderFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getOrderSocketStart = (state, action) => {
  return updateObject(state, { error: null });
};

const getOrderSocketSuccess = (state, action) => {
  let newOrders = action.payload
  let filterNewOrders = []

  newOrders.map((item, newOrdersIndex) => {
    //updated orders coming
    if (item?.update) {
      state.orderList.map((oldItem, index) => {
        if (oldItem.id === item.id) {
          state.orderList.splice(index, 1);
        }
      });
    }
    // checks if pending order already exists
    else if (state.orderList.some(obj => obj.id === item.id)) {
      filterNewOrders.push(newOrders[newOrdersIndex])
    }
  });

  // removes pending order
  if (filterNewOrders.length > 0)
    newOrders = newOrders.filter(item => {
      return !filterNewOrders.some(obj => obj.id === item.id);
    });

  let order = newOrders.concat(state.orderList);
  //let newOrderList = [];
  // action.payload?.map((item) => {
  //   newOrderList = [...newOrderList, { ...item, ringing: true }];
  // });
  //let order = newOrderList.concat(state.orderList);
  return updateObject(state, {
    orderList: order,
    error: null,
    loading: false,
  });
};

const getOrderSocketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updateOrderStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const updateOrderSuccess = (state, action) => {
  let selectOrder = {
    ...state.updatedOrderItem,
    delivery_status: state.updateStatu,
  };
  return updateObject(state, {
    modalItem: selectOrder,
    orderList: state.orderList.map((x) =>
      x.id === selectOrder.id ? selectOrder : x
    ),
    error: null,
    loading: false,
  });
};

const updateOrderFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const changeRestaurantSettingsSuccess = (state, action) => {
  localStorage.setItem("restaurant_status", action.payload);
  return updateObject(state, {
    error: null,
    providerStatuChangeloading: false,
    restaurant_status: action.payload,
  });
};

const changeRestaurantSettingsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    providerStatuChangeloading: false,
  });
};

const changeRestaurantSettingsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    providerStatuChangeloading: true,
  });
};

const getOrderhistoryStart = (state, action) => {
  return updateObject(state, {
    error: null,
    orderHistoryListLoader: true,
  });
};
const changeMenuFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    providerStatuChangeloading: false,
  });
};

const changeMenuStart = (state, action) => {
  return updateObject(state, {
    error: null,
    providerStatuChangeloading: true,
  });
};

const changeMenuSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    providerStatuChangeloading: false,
    menuList: action.payload,
  });
};

const updateProductFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    providerProductUpdateLoading: false,
    menuList: [],
  });
};

const updateProductStart = (state, action) => {
  return updateObject(state, {
    error: null,
    providerProductUpdateLoading: true,
  });
};

const updateProductSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    providerProductUpdateLoading: false,
  });
};

const addServiceProviderFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    addServiceProviderLoading: false,
  });
};

const addServiceProviderStart = (state, action) => {
  return updateObject(state, {
    error: null,
    addServiceProviderLoading: true,
  });
};

const addServiceProviderSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    addServiceProviderLoading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ON_CHANGE_ACTIVATION:
      return onChangeSidebar(state, action);
    case actionTypes.ON_CHANGE_MENU:
      return onChangeMenu(state, action);
    case actionTypes.GET_ORDER_REQUEST:
      return getOrderStart(state, action);
    case actionTypes.GET_ORDER_FAILURE:
      return getOrderFail(state, action);
    case actionTypes.GET_ORDER_SUCCESS:
      return getOrderSuccess(state, action);
    /************************************************ */
    case actionTypes.GET_ORDER_HISTORY_REQUEST:
      return getOrderhistoryStart(state, action);
    /************************************************ */
    case actionTypes.UPDATE_ORDER_REQUEST:
      return updateOrderStart(state, action);
    case actionTypes.UPDATE_ORDER_FAILURE:
      return updateOrderFail(state, action);
    case actionTypes.UPDATE_ORDER_SUCCESS:
      return updateOrderSuccess(state, action);
    /************************************************ */
    case "GET_ORDER_SOCKET_PENDING":
      return getOrderSocketStart(state, action);
    case "GET_ORDER_SOCKET_REJECTED":
      return getOrderSocketFail(state, action);
    case "GET_ORDER_SOCKET_FULFILLED":
      return getOrderSocketSuccess(state, action);
    /**************************************************** */
    case actionTypes.CHANGE_RESTAURANTSETTINGS_REQUEST:
      return changeRestaurantSettingsStart(state, action);
    case actionTypes.CHANGE_RESTAURANTSETTINGS_FAILURE:
      return changeRestaurantSettingsFail(state, action);
    case actionTypes.CHANGE_RESTAURANTSETTINGS_SUCCESS:
      return changeRestaurantSettingsSuccess(state, action);
    /**************************************************** */
    case actionTypes.GET_MENUS_REQUEST:
      return changeMenuStart(state, action);
    case actionTypes.GET_MENUS_FAILURE:
      return changeMenuFail(state, action);
    case actionTypes.GET_MENUS_SUCCESS:
      return changeMenuSuccess(state, action);
    /**************************************************** */
    case actionTypes.UPDATE_PRODUCT_STATUS_REQUEST:
      return updateProductStart(state, action);
    case actionTypes.UPDATE_PRODUCT_STATUS_FAILURE:
      return updateProductFail(state, action);
    case actionTypes.UPDATE_PRODUCT_STATUS_SUCCESS:
      return updateProductSuccess(state, action);
    /**************************************************** */
    case actionTypes.ADD_SERVICE_PROVIDER_REQUEST:
      return addServiceProviderStart(state, action);
    case actionTypes.ADD_SERVICE_PROVIDER_FAILURE:
      return addServiceProviderFail(state, action);
    case actionTypes.ADD_SERVICE_PROVIDER_SUCCESS:
      return addServiceProviderSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
