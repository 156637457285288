export const providerStatusArray = [
  { cancellation_Id: "Closed", cancellation_reason: "Kapalı" },
  { cancellation_Id: "Open", cancellation_reason: "Açık" },
  //{ value: "HugeDemand", label: "Yoğunluğa Al" },
];

export const yemeksepetiProviderStatusArray = [
  { cancellation_Id: "Closed", cancellation_reason: "Kapalı" },
  { cancellation_Id: "Open", cancellation_reason: "Açık" },
  {
    cancellation_Id: "Closed_Until_30",
    cancellation_reason: "(30 dk) yoğunluğa al",
  },
  {
    cancellation_Id: "Closed_Until_60",
    cancellation_reason: "(60 dk) yoğunluğa al",
  },
  {
    cancellation_Id: "Closed_Until_120",
    cancellation_reason: "(120 dk) yoğunluğa al",
  },
];

export const paymentStatus = [
  { value: "Cash", label: "Nakit - (Nakit Ödeme)" },
];
export const providerServiceTimeArray = [
  { value: 30, label: "30 Dakika" },
  { value: 35, label: "35 Dakika" },
  { value: 40, label: "40 Dakika" },
  { value: 45, label: "45 Dakika" },
  { value: 50, label: "50 Dakika" },
  { value: 55, label: "55 Dakika" },
  { value: 60, label: "60 Dakika" },
  { value: 65, label: "65 Dakika" },
  { value: 70, label: "70 Dakika" },
  { value: 75, label: "75 Dakika" },
];

export const reasonRejectedData = [
  {
    id: 1,
    label: "Adres Eksikliği",
  },
  {
    id: 2,
    label: "Başka Şubeye Yönlendirme",
  },
  {
    id: 3,
    label: "Bölge Paket Tutarı Altında",
  },
  {
    id: 4,
    label: "Diğer",
  },
  {
    id: 5,
    label: "Gecikmeden Dolayı Kullanıcı İptali",
  },
  {
    id: 6,
    label: "Gönderim Bölgesi Dışı İptal",
  },
  {
    id: 7,
    label: "Hava Muhalefeti",
  },
  {
    id: 8,
    label: "Kullanıcı Birden Fazla Sipariş Verdi",
  },
  {
    id: 9,
    label: "Kullanıcı Notu Geçersiz",
  },
  {
    id: 10,
    label: "Kullanıcı Siparişi Reddetmek İstedi",
  },
  {
    id: 11,
    label: "Motorcu / Motor Problemleri",
  },
  {
    id: 12,
    label: "Ödeme Şekli Yok / POS Cihazı Arızalı",
  },
  {
    id: 13,
    label: "Restoran Kapalı / Erken Kapama",
  },
  {
    id: 14,
    label: "Sipariş Kapıda Kullanıcıya Ulaşılamıyor",
  },
  {
    id: 15,
    label: "Sipariş Kapıdan Döndü",
  },
  {
    id: 16,
    label: "Telefonu Hatalı / Kullanılmıyor",
  },
  {
    id: 17,
    label: "Kullanıcı Siparişi Reddetmek İstedi",
  },
  {
    id: 18,
    label: "Ürün Yok",
  },
  {
    id: 19,
    label: "Yoğunluk",
  },
];
