import {
  ON_CHANGE_ACTIVATION,
  HTTP_REQUEST,
  GET_ORDER,
  CHANGE_RESTAURANTSETTINGS,
  UPDATE_ORDER,
  GET_ORDER_HISTORY,
  GET_REPORT_DASHBOARD,
  GET_CANCELLATIONREASONS,
  GET_MENUS,
  ON_CHANGE_MENU,
  UPDATE_PRODUCT_STATUS,
  ADD_SERVICE_PROVIDER,
} from "../actions/types";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { modifyDateTime } from "../../utils/DateFormatter";

import { baseURL } from "../../config/axios";
import { toast } from "react-toastify";

export const activationOnChange = ({ props, value }) => {
  return {
    type: ON_CHANGE_ACTIVATION,
    payload: { props, value },
  };
};
export const onChangeMenu = ({ props, value }) => {
  return {
    type: ON_CHANGE_MENU,
    payload: { props, value },
  };
};

export const UpdateOrder =
  (body, successCallback, errorHandler) => async (dispatch, getState) => {
    const url = baseURL + `/api/order/update/status`;
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "POST",
        url,
        body: { ...body },
        label: UPDATE_ORDER,
        callBack: (res) => successCallback(res),
        errorHandler: (event) => errorHandler(event),
      },
    });
  };

export const GetOrderwithSocket = () => {
  return async (dispatch, getState) => {
    const { accessToken, userInfo } = getState().authResponse || {};
    let connection = await new HubConnectionBuilder()
      .withUrl(baseURL + "/rtchub", { accessTokenFactory: () => accessToken })
      .withAutomaticReconnect()
      .build();

    connection.onclose(async () => {
      console.log(modifyDateTime(new Date()) + " Closed : ");
      await start(true);
    });
    connection.onreconnected(async (res) => {
      await connection
        .invoke("OnReconnected", modifyDateTime(new Date()) + " Reconnected")
        .then((data) => {
          console.log(modifyDateTime(new Date()) + " Reconnected");
        });

      dispatch(
        GetOrder(
          () => {},
          (err) => {
            toast.error(err?.message, { autoClose: 3000 });
          }
        )
      );

      console.log(modifyDateTime(new Date()) + " Reconnected : ", res);
    });
    connection.onreconnecting((error) => {
      console.log(
        modifyDateTime(new Date()) + " Reconnecting : ",
        error?.message
      );
    });
    connection.on("ProcessMessage", (message) => {
      console.log(
        modifyDateTime(new Date()) + " ProccesMessage listening : ",
        message
      );
    });
    connection.on("ProcessOrder", (message) => {
      console.log(
        modifyDateTime(new Date()) + " ProcessOrder Listening: ",
        message
      );
      for (let i in message) {
        message[i].update = false;
      }

      console.log(
        modifyDateTime(new Date()) + " ProcessOrder newArray Listening: ",
        message
      );
      return dispatch({
        type: "GET_ORDER_SOCKET_FULFILLED",
        payload: message,
      });
    });
    connection.on("ProcessUpdatedOrderProvider", (message) => {
      console.log(
        modifyDateTime(new Date()) + "ProcessUpdatedOrderProvider Listening: ",
        message
      );
      for (let i in message) {
        message[i].update = true;
      }
      return dispatch({
        type: "GET_ORDER_SOCKET_FULFILLED",
        payload: message,
      });
    });
    connection.on("ProcessOrderTest", (message) => {
      //connection.stop();
      window.location.reload();
    });

    async function start(closed) {
      debugger;
      await connection
        .start()
        .then(() => {
          if (closed) {
            connection.invoke(
              "OnStartAfterClosed",
              modifyDateTime(new Date()) + " OnStartAfterClosed"
            );
          }

          dispatch(
            GetOrder(
              () => {},
              (err) => {
                toast.error(err?.message, { autoClose: 3000 });
              }
            )
          );
        })
        .catch((err) => console.log(modifyDateTime(new Date()) + " " + err));
    }

    await start(false);
  };
};

export const ChangeRestaurantStatus =
  (body, successCallback, errorHandler) => async (dispatch, getState) => {
    const url = baseURL + `/api/restaurant/provider/update/status`;

    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "POST",
        url,
        body: { ...body },
        label: CHANGE_RESTAURANTSETTINGS,
        callBack: (res) => successCallback(res),
        errorHandler: (event) => errorHandler(event),
      },
    });
  };

export const ChangeServiceTime =
  (body, successCallback, errorHandler) => async (dispatch, getState) => {
    const url = baseURL + `/api/restaurant/deliverytime/update`;
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "POST",
        url,
        body: { ...body },
        label: CHANGE_RESTAURANTSETTINGS,
        callBack: (res) => successCallback(res),
        errorHandler: (event) => errorHandler(event),
      },
    });
  };

export const ChangeCourierUpdate =
  (body, successCallback, errorHandler) => async (dispatch, getState) => {
    const url = baseURL + `/api/restaurant/provider/courier/update`;
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "POST",
        url,
        body: { ...body },
        label: CHANGE_RESTAURANTSETTINGS,
        callBack: (res) => successCallback(res),
        errorHandler: (event) => errorHandler(event),
      },
    });
  };

export const GetOrder = (successCallback, errorHandler) => async (dispatch) => {
  const url = baseURL + `/api/order/get/pending`;
  await dispatch({
    type: HTTP_REQUEST,
    payload: {
      method: "GET",
      url,
      label: GET_ORDER,
      callBack: () => successCallback(),
      errorHandler: (event) => errorHandler(event),
    },
  });
};
export const GetCancellationReasons =
  (body, successCallback, errorHandler) => async (dispatch, getState) => {
    const url =
      baseURL + `/api/order/cancellationreasons?orderId=${body.orderId}`;
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "GET",
        url,
        label: GET_CANCELLATIONREASONS,
        callBack: (res) => successCallback(res),
        errorHandler: (event) => errorHandler(event),
      },
    });
  };

export const GetOrderHistory =
  (body, successCallback, errorHandler) => async (dispatch) => {
    const url =
      baseURL +
      `/api/order/get/history?orderStatus=${body.orderStatus}&SearchQuery=${body.SearchQuery}&PageNumber=${body.PageNumber}&PageSize=${body.PageSize}&OrderBy=${body.OrderBy}&Fields=${body.Fields}&StartDate=${body.StartDate}&EndDate=${body.EndDate}`;
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "GET",
        url,
        label: GET_ORDER_HISTORY,
        callBack: (res) => successCallback(res),
        errorHandler: (err) => errorHandler(err),
      },
    });
  };

export const GetDashBoardInformation =
  (body, successCallback, errorHandler) => async (dispatch) => {
    const url = baseURL + `/api/report/dashboard/daily`;
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "GET",
        url,
        label: GET_REPORT_DASHBOARD,
        callBack: (res) => successCallback(res),
        errorHandler: (err) => errorHandler(err),
      },
    });
  };

export const GetMenus =
  (params, successCallback, errorHandler) => async (dispatch) => {
    const url =
      baseURL + `/api/restaurant/menu?restaurant_id=${params.restaurant_id}`;

    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "GET",
        url,
        label: GET_MENUS,
        callBack: (res) => successCallback(res),
        errorHandler: (event) => errorHandler(event),
      },
    });
  };

export const ChangeProductStatus =
  (productId, body, successCallback, errorHandler) =>
  async (dispatch, getState) => {
    const url = baseURL + `/api/products/${productId}/status`;
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "PUT",
        url,
        body: { ...body },
        label: UPDATE_PRODUCT_STATUS,
        callBack: (res) => {
          successCallback(res);
        },
        errorHandler: (event) => {
          errorHandler(event);
        },
      },
    });
  };

export const AddServiceProvider =
  (restaurantId, serviceProviderId, body, successCallback, errorHandler) =>
  async (dispatch, getState) => {
    const url =
      baseURL +
      `/api/restaurant/${restaurantId}/serviceProviderId/${serviceProviderId}`;
    await dispatch({
      type: HTTP_REQUEST,
      payload: {
        method: "PUT",
        url,
        body: { ...body },
        label: ADD_SERVICE_PROVIDER,
        callBack: (res) => {
          successCallback(res);
        },
        errorHandler: (event) => {
          errorHandler(event);
        },
      },
    });
  };
