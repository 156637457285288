export const ProviderInformationFields = [
  { key: "providerName", label: "Sağlayıcı", _style: { width: "20%" } },
  { key: "store_name", label: "Şube", _style: { width: "25%" } },
  { key: "providerStatus", label: "Restoran Durumu", _style: { width: "20%" } },
  // {
  //   key: "providerServiceTime",
  //   label: "Anlık Servis Süresi",
  //   _style: { width: "15%" },
  // },
  {
    key: "providerCourierStatus",
    label: "Kurye Durumu",
    _style: { width: "15%" },
  },
];
