import { combineReducers } from "redux";
import authReducer from "./authReducer";
import sideBarReducer from "./sideBarReducer";
import ModalControlReducer from "./ModalControlReducer";
import ActivationReducer from "./ActivationReducer";
import GetSettingsReducer from "./GetSettingsReducer";

export default combineReducers({
  authResponse: authReducer,
  sideBarResponse: sideBarReducer,
  ModalControlResponse: ModalControlReducer,
  ActivationResponse: ActivationReducer,
  GetSettingsResponse: GetSettingsReducer,
});
